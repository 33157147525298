<template>
    <div class="animated fadeIn">
        <b-card>
            <template v-slot:header>
                <h5>Terima Pembelian</h5>
            </template>
            <!-- FILTER -->
            <b-form @submit.prevent="handleFilterPembelian">
                <div class="row">
                    <div class="col-md-3">
                        <b-form-group label="No Invoice" label-for="filterInput">
                            <b-form-input v-model="filter.no_invoice" name="filterInvoice" id="filterInput" type="search"
                                placeholder="No Invoice"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-4">
                        <b-form-group label="Filter tanggal">
                            <date-range-picker class="w-100" v-model="dateRange" ref="picker"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :minDate="null" :maxDate="new Date()"
                                :singleDatePicker="false" :timePicker24Hour="true" :showWeekNumbers="true"
                                :showDropdowns="true" :autoApply="true" @select="handleSelectDate">
                            </date-range-picker>
                        </b-form-group>
                    </div>
                    <div class="col-md-3">
                        <b-form-group label="Supplier">
                            <multiselect v-model="filter.id_supplier" :options="supplier" :internal-search="true"
                                :allow-empty="false" deselect-label="" select-label="" label="nama" track-by="id"
                                placeholder="pilih supplier" />
                        </b-form-group>
                    </div>
                    <div class="col d-flex align-items-center">
                        <b-button :disabled="isLoading" type="submit" class="mt-2 mr-2" variant="pgiBtn">
                            Filter
                        </b-button>
                        <b-button class="mt-2" variant="pgiBtn" name="resetTableBtn" :disabled="isLoading"
                            @click="onReset()">
                            Reset
                        </b-button>
                    </div>
                </div>
            </b-form>
            <!-- FILTER -->
            <b-table small bordered hover show-empty :busy="isLoading" :items="pembelians.data" :fields="fields"
                :perPage="pembelians.per_page" responsive class="my-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data jenis barang.
                </template>

                <template v-slot:cell(no_invoice)="row">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <b class="d-block">{{ row.item.no_invoice }}</b>
                            <span>Tgl : {{ row.item.tanggal }}</span>
                        </div>
                    </div>
                </template>

                <template v-slot:cell(supplier)="row">
                    <div class="d-flex align-items-center justify-content-between">
                        <div v-if="row.item.is_online === 1">
                            <small class="d-block">{{ row.item.supplier }}</small>
                            <span>{{ row.item.shop_name }}</span>
                        </div>
                        <div v-else>
                            <span>{{ row.item.supplier }}</span>
                        </div>
                        <!-- <div v-if="row.item.is_online === 2">
                            <span>{{ row.item.supplier }}</span>
                        </div> -->
                    </div>
                </template>

                <template v-slot:cell(totalPembelian)="row">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>Rp.</div>
                        <div class="text-end">{{ row.item.totalPembelian }}</div>
                    </div>
                </template>
                <template v-slot:cell(status)="row">
                    <b-badge variant="secondary" v-if="row.item.status == 0">Belum Proses</b-badge>
                    <b-badge variant="primary" v-if="row.item.status == 1">Sudah Diproses</b-badge>
                    <b-badge variant="info" class="text-white" v-if="row.item.status == 2">Sudah Dibayar</b-badge>
                    <b-badge variant="success" v-if="row.item.status == 3">Diterima</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 4">Dibatalkan</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 5">Barang Selisih</b-badge>
                    <b-badge variant="warning" v-if="row.item.status == 6">Permintaan Void</b-badge>
                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <b-link :to="`${$route.path}/${row.item.id}/detail`">
                            <b-button size="sm" class="mr-3 table-button view" v-b-tooltip.hover title="Detail"
                                name="pembelianViewBtn">
                                <i class="fa fa-info"></i>
                            </b-button>
                        </b-link>
                    </div>
                </template>
            </b-table>
            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <span class="ml-2">
                    Menampilkan
                    {{ pembelians.from + " - " + pembelians.limit }}
                    dari
                    {{ pembelians.total }}
                </span>
                <b-pagination class="mb-0" v-model="pembelians.current_page" :total-rows="pembelians.total"
                    :per-page="pembelians.per_page" aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>
    </div>
</template>

<script>

import DateRangePicker from 'vue2-daterange-picker'
import { mapState } from 'vuex'
export default {
    components: { DateRangePicker },
    name: 'pembelian',
    data() {
        return {
            dateRange: { startDate: null, endDate: null },
            filter: {
                flag: 2,
                no_invoice: null,
                from: null,
                to: null,
                id_supplier: null
            },
            fields: [
                { key: "no_invoice", label: "Invoice" },
                { key: "supplier", label: "Supplier" },
                { key: "totalUnit", label: "Total Unit" },
                { key: "totalPembelian", label: "Total Pembelian" },
                { key: "status", label: "Status" },
                { key: "action", label: "Aksi" },
            ],
        }
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        pembelians() {
            return this.$store.state.pembelian.pembelian_all
        },
        supplier() {
            return this.$store.state.global_data.supplierList
        }
    },
    methods: {
        dispatchPembelian() {
            this.$store.dispatch("getDataPembelian", {
                flag: 2
            })
        },

        handleSelectDate(value) {
            const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
            const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
            this.filter.from = startDate
            this.filter.to = endDate
        },

        async handleFilterPembelian() {
            const query = {
                no_invoice: this.filter.no_invoice,
                id_supplier: this.filter.id_supplier ? this.filter.id_supplier.id : null,
                from: this.filter.from,
                to: this.filter.to,
            }
            this.$store.dispatch("getDataPembelian", {
                ...query,
                flag: 2,

            })
        },
        onPageChange(value) {
            this.$store.dispatch("getDataPembelian", {
                flag: 2,
                page: value
            })
        },
        onReset() {
            this.filter = {
                flag: 2,
                no_invoice: null,
                from: null,
                to: null,
                id_supplier: null
            }
            this.dateRange = { startDate: null, endDate: null }
            this.$store.dispatch("getDataPembelian", {
                flag: 2
            })
        },
    },
    mounted() {
        this.$store.dispatch("getSupplier")
        this.dispatchPembelian()
    }
}
</script>
